import type { RouteRecordRaw } from 'vue-router';

export const actionRoutes: RouteRecordRaw[] = [
  {
    name: 'action-index',
    component: () => import('./../pages/index.vue'),
    path: '/actions',
    meta: {
      middleware: 'auth',
    },
  },
];
