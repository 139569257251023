import { provideApolloClient } from '@vue/apollo-composable';
import { cloneDeep } from '@apollo/client/utilities';
import {
  ALERTS_QUERY_LIST,
  CREATE_ALERT,
  CREATE_ALERT_USERS,
  DELETE_ALERT,
  UDPATE_ALERT_USERS,
  UPDATE_ALERT,
  ALERT_ANALYTIC_TYPE_QUERY_LIST,
} from '~/graphql/alert';
import type { Alert } from '~/types/alerts';
import { cleanJsonBeforeSend } from '~/helpers/data';

interface State {
  alerts: Alert[];
  alerts_types: string[];
}

export const alertStore = defineStore({
  id: 'alertStore',
  state: (): State => ({
    alerts: [],
    alerts_types: [],
  }),
  getters: {
    getAlerts: (state) => {
      return state.alerts.filter((alert) =>
        meterStore()
          .getMeters.map((meter) => meter.id)
          .includes(alert.meter_id),
      );
    },
    getAlert: (state) => {
      return (id: number) => state.alerts.find((alert) => alert.id === id);
    },
    getAlertByMeterIdAndType:
      (state) =>
      (meterId: number, type: string): any => {
        const filteredAlerts = state.alerts.filter((item) => item.meter_id === meterId && item.type === type);
        if (filteredAlerts.length > 0) return filteredAlerts[0];
      },
    getAlertAnalyticType(): any {
      return this.alerts_types;
    },
  },
  actions: {
    async loadAlerts(): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      const alerts = await apolloClient.query({
        query: ALERTS_QUERY_LIST,
        variables: { site_id: globalStore().getSelectedSite },
      });
      this.alerts = cloneDeep(alerts.data.alerts);
    },
    async loadAlertAnalyticType(): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      const alertAnalyticType = await apolloClient.query({
        query: ALERT_ANALYTIC_TYPE_QUERY_LIST,
      });
      this.alerts_types = alertAnalyticType.data.alert_types;
    },
    async createAlert(alert): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      const alertCleaned = cleanJsonBeforeSend({ jsonObject: alert, keys: ['alert_users', 'users', 'incidents'] });
      // Insert Alert
      const responseAlert = await apolloClient.mutate({
        mutation: CREATE_ALERT,
        variables: { object: alertCleaned },
      });

      if (responseAlert.data.insert_alerts_one) {
        const alertUsers = alert.users?.map((user: string) => {
          return { alert_id: responseAlert.data.insert_alerts_one.id, user_id: user };
        });

        // Insert Alert Users
        if (alertUsers) {
          await apolloClient.mutate({
            mutation: CREATE_ALERT_USERS,
            variables: { objects: alertUsers },
          });
        }

        this.alerts.push({
          ...responseAlert.data.insert_alerts_one,
          alert_users: alertUsers
            ? alertUsers.map((user: { user_id: string }) => {
                return { user_id: user.user_id, user: userStore().getUser(user.user_id) };
              })
            : [],
        });
      }
    },
    async updateAlert(alert: any) {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      const alertCleaned = cleanJsonBeforeSend({ jsonObject: alert, keys: ['alert_users', 'users', 'id'] });
      // Insert Alert
      const responseAlert = await apolloClient.mutate({
        mutation: UPDATE_ALERT,
        variables: {
          id: alert.id,
          object: alertCleaned,
        },
      });
      if (responseAlert.data.update_alerts.returning[0]) {
        const alertUsers = alert.users.map((user: string) => {
          return { alert_id: alert.id, user_id: user };
        });
        // Insert Alert Users
        await apolloClient.mutate({
          mutation: UDPATE_ALERT_USERS,
          variables: { id: alert.id, objects: alertUsers },
        });

        const index = this.alerts.findIndex((item) => item.id === alert.id);
        this.alerts[index] = {
          ...responseAlert.data.update_alerts.returning[0],
          alert_users: alertUsers.map((user: { user_id: string }) => {
            return { user_id: user.user_id, user: userStore().getUser(user.user_id) };
          }),
        };
      }
    },
    async deleteAlert(id: number): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      await apolloClient.mutate({
        mutation: DELETE_ALERT,
        variables: { id },
      });
      const index = this.alerts.findIndex((item) => item.id === id);
      this.alerts.splice(index, 1);
    },
  },
});
