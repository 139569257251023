<script setup lang="ts">
const toast = toastStore();
</script>
<template>
  <div aria-live="assertive" class="pointer-events-none fixed z-[9999] inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-x-[100%]"
        enter-to-class="translate-x-0 opacity-100"
        leave-active-class="transition ease-out duration-300"
        leave-from-class="translate-x-0"
        leave-to-class="translate-x-[100%]"
      >
        <div
          v-if="toast.getToast?.title"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <ui-icon v-if="toast.getToast.type === 'success'" class="h-5 w-5 text-green-500" name="CircleCheck" />
                <ui-icon v-else-if="toast.getToast.type === 'error'" class="h-5 w-5 text-red-500" name="CircleX" />
              </div>
              <div class="ml-3 w-0 flex-1">
                <p class="text-sm font-medium text-gray-900">{{ toast.getToast.title }}</p>
                <p class="mt-1 text-sm text-gray-500">{{ toast.getToast.message }}</p>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
