import { gql } from '@apollo/client/core';

export const USERS_QUERY_LIST = gql`
  query UsersQuery {
    users {
      client_id
      email
      first_name
      id
      last_name
      picture_url
      role
      client {
        name
        id
      }
    }
  }
`;
