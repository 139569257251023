import { gql } from '@apollo/client/core';

export const SITES_QUERY_LIST = gql`
  query SitesQuery($client_id: Int) {
    sites(where: { client_id: { _eq: $client_id } }) {
      client_id
      id
      name
      production_start_weekday
      production_hours {
        id
        day_id
        end
        start
        created_at
        updated_at
      }
    }
  }
`;

export const SITE_QUERY = gql`
  query SiteQuery($id: Int!) {
    sites(where: { id: { _eq: $id } }) {
      client_id
      id
      name
      production_start_weekday
    }
  }
`;

export const SITE_UPDATE = gql`
  mutation UpdateSite($object: sites_set_input, $id: Int!) {
    update_sites_by_pk(pk_columns: { id: $id }, _set: $object) {
      client_id
      id
      name
      production_hours {
        id
        day_id
        end
        start
      }
    }
  }
`;

export const SITE_INSERT = gql`
  mutation InsertSite($object: sites_insert_input!) {
    insert_sites_one(object: $object) {
      id
      name
    }
  }
`;
