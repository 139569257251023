import { defineStore } from 'pinia';
import { provideApolloClient } from '@vue/apollo-composable';
import { cloneDeep } from '@apollo/client/utilities';
import type { Unit } from '~/types/unit';
import { UNITS_QUERY_LIST } from '~/graphql/units';

export const unitsStore = defineStore({
  id: 'units',
  state: () => ({
    units: {
      list: [] as Array<Unit>,
    },
  }),
  getters: {
    getUnits(): Unit[] {
      return this.units.list;
    },
  },
  actions: {
    getUnit(unitId: number): Unit | undefined {
      return this.units.list.find((item) => item.id === unitId);
    },
    async loadUnits() {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const units: { data: { units: Unit[] } } = await apolloClient.query({ query: UNITS_QUERY_LIST });
      this.units.list = cloneDeep(units.data.units);
    },
  },
});
