import type { RouteRecordRaw } from 'vue-router';

export const productRoutes: RouteRecordRaw[] = [
  {
    name: 'product-index',
    component: () => import('./../pages/index.vue'),
    path: '/products',
    meta: {
      middleware: 'auth',
    },
  },
  {
    name: 'product-view',
    component: () => import('./../pages/view.vue'),
    path: '/product/:id',
    meta: {
      middleware: 'auth',
    },
  },
];
