import { defineStore } from 'pinia';
import { provideApolloClient } from '@vue/apollo-composable';
import { METER_TYPES_QUERY_LIST } from '~/graphql/meter_types';

export const meterTypesStore = defineStore({
  id: 'meterTypesStore',
  state: () => ({
    meter_types: {
      list: [] as Array<any>,
    },
  }),
  getters: {
    getMeterTypes(): any[] {
      return this.meter_types.list;
    },
  },
  actions: {
    async loadMeterTypes(): Promise<any> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const meter_types_list = await apolloClient.query({
        query: METER_TYPES_QUERY_LIST,
      });

      this.meter_types.list = meter_types_list?.data?.meter_types;
    },
    getMeterTypeFromName(name: string): any {
      return this.meter_types.list.find((item) => item.name === name);
    },
  },
});
