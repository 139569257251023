import type { ApiGetContractById, ApiGetContracts, Contract, Contracts, InsertContract, UpdateContract } from '~/types/contract';
import { provideApolloClient } from '@vue/apollo-composable';
import { cloneDeep } from '@apollo/client/utilities';
import {
  CONTRACT_MUTATION_INSERT,
  CONTRACT_MUTATION_UPDATE,
  CONTRACT_QUERY_BY_ID,
  CONTRACT_QUERY_LIST,
  TARIFF_MUTATION_INSERT,
} from '~/graphql/contract';
import { cleanJsonBeforeSend } from '~/helpers/data';

interface State {
  contracts: Contracts;
  loading: boolean;
}

export const contractStore = defineStore({
  id: 'contractStore',
  state: (): State => ({
    contracts: [],
    loading: false,
  }),
  getters: {
    getContracts(): Contracts {
      return this.contracts;
    },
    getElectricityContracts(): Contracts {
      return this.contracts
        .filter((contract) => contract.energy_type === 'Electricity')
        .sort((a, b) => new Date(b.end).getTime() - new Date(a.end).getTime());
    },
    getGasContracts(): Contracts {
      return this.contracts
        .filter((contract) => contract.energy_type === 'Gas')
        .sort((a, b) => new Date(b.end).getTime() - new Date(a.end).getTime());
    },
    getContractById() {
      return (id: number): Contract => this.contracts.find((contract) => contract.id === id);
    },
    getContractBySiteId() {
      return (siteId: number): Contracts => this.contracts.filter((contract) => contract.site_id === siteId);
    },
  },
  actions: {
    async loadContracts(): Promise<void> {
      this.loading = true;
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      const contracts = await apolloClient.query<ApiGetContracts>({
        query: CONTRACT_QUERY_LIST,
        variables: {
          site_id: globalStore().getSelectedSite,
        },
      });
      this.contracts = cloneDeep(contracts?.data?.contracts);
      this.loading = false;
    },
    async loadContractById(id: number): Promise<Contract> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      const contract = await apolloClient.query<ApiGetContractById>({ query: CONTRACT_QUERY_BY_ID, variables: { id } });
      return contract.data?.contracts_by_pk;
    },
    async insertContract(contract: InsertContract): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const object = {
        site_id: globalStore().getSelectedSite[0],
        contract_power: contract.contract_power,
        emission_factor: contract.emission_factor,
        energy_type: contract.energy_type,
        start: contract.start,
        end: contract.end,
        tariffs: {
          data: contract.tariffs.map((tariff) => ({
            name: tariff.name,
            value: tariff.value,
            months: tariff.months?.length === 0 ? null : tariff.months,
            tariff_schedules: {
              data: tariff.tariff_schedules.map((tariffSchedule) => ({
                days_of_week: tariffSchedule.days_of_week,
                time_range: tariffSchedule.time_range,
              })),
            },
          })),
        },
      };

      await apolloClient.mutate({ mutation: CONTRACT_MUTATION_INSERT, variables: { object: object } });
      await this.loadContracts();
    },
    async updateContract(contractId: number, contract: UpdateContract, tariffs: Contract['tariffs']): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      await apolloClient.mutate({ mutation: CONTRACT_MUTATION_UPDATE, variables: { object: contract, id: contractId } });

      const tariffObjects = tariffs.map((tariff) => {
        const tariffCleaned = cleanJsonBeforeSend({ jsonObject: tariff, keys: ['tariff_schedules', 'id'] });
        return {
          contract_id: contractId,
          ...tariffCleaned,
          months: tariff.months?.length === 0 ? null : tariff.months,
          tariff_schedules: {
            data: tariff.tariff_schedules.map((tariffSchedule) => ({
              days_of_week: tariffSchedule.days_of_week,
              time_range: tariffSchedule.time_range,
            })),
          },
        };
      });

      await apolloClient.mutate({ mutation: TARIFF_MUTATION_INSERT, variables: { objects: tariffObjects } });
      await this.loadContracts();
    },
  },
});
