import { gql } from '@apollo/client/core';

export const SITE_UPDATE_PRODUCTION_HOURS = gql`
  mutation InsertProductionHours($objects: [production_hours_insert_input!]!, $siteId: Int!) {
    delete_production_hours(where: { site_id: { _eq: $siteId } }) {
      returning {
        id
      }
    }
    insert_production_hours(objects: $objects, on_conflict: { constraint: production_hours_pkey, update_columns: [start, end, day_id] }) {
      returning {
        id
      }
    }
  }
`;

export const PRODUCTION_HOURS_DELETE = gql`
  mutation DeleteProductionHours($id: Int!) {
    delete_production_hours_by_pk(id: $id) {
      id
    }
  }
`;
