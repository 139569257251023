import { defineStore } from 'pinia';
import type { Toast } from '~/types/toast';

interface State {
  toast: Toast | null;
}

export const toastStore = defineStore({
  id: 'toastStore',
  state: (): State => ({
    toast: null,
  }),
  getters: {
    getToast(): Toast | null {
      return this.toast;
    },
  },
  actions: {
    async showToast(toast: Toast): Promise<any> {
      this.toast = toast;
      setTimeout(() => {
        this.toast = null;
      }, 5000);
    },
  },
});
