import { gql } from '@apollo/client/core';

export const METER_QUERY_LIST = gql`
  query MetersQuery($site_id: [Int!]!) {
    meters(where: { area: { site_id: { _in: $site_id } } }) {
      area {
        name
        production_reporting_frequency
        production_unit_id
        site_id
        site {
          client_id
        }
        id
        parent_id
        parent {
          id
          name
        }
      }
      meter_type {
        energy_type
        id
        name
        step_seconds
      }
      id
      data_source_id
    }
  }
`;
