import { provideApolloClient } from '@vue/apollo-composable';
import { USERS_QUERY_LIST } from '~/graphql/user';
import type { Users, User, ApiUserQuery } from '~/types/user';

interface State {
  users: Users;
  currentUser: User | null;
}

export const userStore = defineStore({
  id: 'userStore',
  state: (): State => ({
    users: [],
    currentUser: null,
  }),
  getters: {
    getAllUsers(): Users {
      return this.users;
    },
    getUsers(): Users {
      return this.users.filter((user) => user.client_id === globalStore().getSelectedClient);
    },
    getUser() {
      return (userId: string) => {
        return this.users.find((u) => u.id === userId) ?? null;
      };
    },
    getCurrentUser(): User | null {
      return this.currentUser;
    },
  },
  actions: {
    async loadUsers(): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      const response = await apolloClient.query<ApiUserQuery>({ query: USERS_QUERY_LIST });
      this.users = response.data.users ?? [];

      // Set current user
      const firebase = firebaseStore();
      const firebaseUser = firebase.getFirebaseUser;

      if (firebaseUser) {
        this.currentUser = this.users.find((user) => user.id === firebaseUser.uid) ?? null;
      }
    },
    filterUsers(ids: any) {
      const users: any = this.users.filter((u: any) => {
        return ids?.length && ids?.includes(u?.id);
      });
      return users;
    },
  },
});
