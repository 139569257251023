import { cloneDeep } from '@apollo/client/utilities';

export const cleanJsonBeforeSend = <T>(options: {
  jsonObject: T extends Record<string, any> ? T : any;
  keys?: keyof T extends string ? Array<keyof T> : Array<string>;
}): T => {
  const newObject = cloneDeep(options.jsonObject);
  const removeKeys = ['_created_at', '_updated_at', '_created_by', '_updated_by', '__typename', ...(options.keys || [])];

  removeKeys.forEach((key) => {
    delete newObject[key];
  });

  return newObject;
};
