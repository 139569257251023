<script setup lang="ts">
import type { IconName } from './Icon.vue';

const props = withDefaults(
  defineProps<{
    type?: 'button' | 'submit' | 'reset';
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    color?: 'primary' | 'secondary' | 'tertiary' | 'delete' | 'disabled';
    block?: boolean;
    disabled?: boolean;
    loading?: boolean;

    // left icon
    leftIcon?: IconName;
    leftIconClass?: string;

    // left icon
    rightIcon?: IconName;
    rightIconClass?: string;
  }>(),
  {
    type: 'button',
    size: 'md',
    color: 'primary',
  },
);

const getSize = computed(() => {
  switch (props.size) {
    case 'xs':
      return 'px-2 h-[26px] text-xs rounded-md';
    case 'sm':
      return 'px-2 h-[30px] text-sm rounded-md';
    case 'md':
      return 'px-2.5 h-[32px] text-sm rounded-md';
    case 'lg':
      return 'px-3 py-2 text-sm rounded-md';
    case 'xl':
      return 'px-3.5 py-[11px] text-sm rounded-md';
    default:
      return 'px-2.5 py-1.5 text-sm rounded-md';
  }
});

const iconSize = computed(() => {
  switch (props.size) {
    case 'xs':
      return 'w-3 h-3';
    case 'sm':
      return 'w-4 h-4';
    case 'md':
      return 'w-4 h-4';
    case 'lg':
      return 'w-5 h-5';
    case 'xl':
      return 'w-6 h-6';
    default:
      return 'w-4 h-4';
  }
});

const getColor = computed(() => {
  switch (props.color) {
    case 'primary':
      return 'bg-primary-500 hover:bg-primary-600 text-white';
    case 'secondary':
      return 'bg-white hover:bg-gray-50 text-gray-600 ring-1 ring-inset ring-gray-200';
    case 'tertiary':
      return 'bg-white text-blue-600 hover:bg-blue-50 hover:text-blue-800';
    case 'delete':
      return 'bg-white border border-red-500 text-red-500 hover:text-red-800 hover:border-red-800 outline-none';
    case 'disabled':
      return 'bg-gray-100 text-gray-400';
    default:
      return 'bg-primary-500 hover:bg-primary-600 text-white';
  }
});

const getLoaderColor = computed(() => {
  switch (props.color) {
    case 'primary':
      return 'text-white';
    case 'secondary':
      return 'text-gray-600';
    case 'tertiary':
      return 'text-blue-600';
    case 'delete':
      return 'text-red-500';
    case 'disabled':
      return 'text-gray-400';
    default:
      return 'text-white';
  }
});
</script>

<template>
  <button
    :type="type"
    class="relative flex items-center justify-center cursor-pointer"
    :class="[block ? 'w-full' : '', getSize, getColor, loading || disabled ? 'cursor-not-allowed' : '']"
    :disabled="loading || disabled"
  >
    <div
      v-if="loading"
      class="w-full h-full bg-inherit flex items-center justify-center absolute top-0 left-0 right-0 bottom-0 z-10"
      :class="getSize"
    >
      <ui-spin-loader :class="iconSize" :color="getLoaderColor" />
    </div>
    <ui-icon
      v-if="leftIcon"
      :name="leftIcon"
      class="w-4 h-4 z-0"
      :default-class="leftIconClass"
      :class="[$slots.default ? 'mr-2' : '', leftIconClass, iconSize]"
    />
    <p class="whitespace-nowrap">
      <slot />
    </p>
    <ui-icon
      v-if="rightIcon"
      :name="rightIcon"
      class="w-4 h-4 z-0"
      :default-class="rightIconClass"
      :class="[$slots.default ? 'ml-2' : '', rightIconClass, iconSize]"
    />
  </button>
</template>
