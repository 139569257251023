import { gql } from '@apollo/client/core';

export const NOTIFICATION_QUERY_LIST = gql`
  query NotificationQuery($user_id: String!) {
    notifications(where: { created_for: { _eq: $user_id }, read: { _eq: false } }) {
      id
      read
      type
      url
      data
      created_for
      created_by
      created_at
    }
  }
`;

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription NotificationSubscription($user_id: String!) {
    notifications(where: { created_for: { _eq: $user_id }, read: { _eq: false } }) {
      id
    }
  }
`;

export const NOTIFICATION_MUTATION_UPDATE = gql`
  mutation NotificationMutationUpdate($id: Int!, $object: notifications_set_input!) {
    update_notifications_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`;
export const NOTIFICATION_MUTATION_INSERT = gql`
  mutation NotificationMutationInsert($object: notifications_insert_input!) {
    insert_notifications_one(object: $object) {
      type
    }
  }
`;
