import { defineStore } from 'pinia';
import { METER_QUERY_LIST } from '~/graphql/meter';
import { AREA_QUERY_UPDATE } from '~/graphql/area';
import type { Meter } from '~/types/meter';

interface State {
  meters: Meter[];
  energyTypes: [];
}

export const meterStore = defineStore({
  id: 'meterStore',
  state: (): State => ({
    meters: [],
    energyTypes: [],
  }),
  getters: {
    getMeters(): Meter[] {
      return this.meters;
    },
    getMetersByType() {
      return (type: string): Meter[] | [] => {
        return this.meters.filter((meter) => meter.meter_type.energy_type === type);
      };
    },
    getMeter() {
      return (id: number): Meter | undefined => this.meters.find((meter) => meter.id === id);
    },
  },
  actions: {
    // Load meters from the API
    async loadMeters(): Promise<any> {
      const { apolloClient } = useApollo();

      // Get meters
      const metersResponse = await apolloClient.query<{ meters: Meter[] }>({
        query: METER_QUERY_LIST,
        variables: {
          site_id: globalStore().getSelectedSite,
        },
      });

      this.meters = metersResponse.data.meters;
    },
    async updateMeter(options: { id: number; form: Meter }): Promise<any> {
      const { apolloClient } = useApollo();
      await apolloClient.mutate<{ meters: Meter[] }>({
        mutation: AREA_QUERY_UPDATE,
        variables: {
          object: {
            name: options.form.area.name,
          },
          id: options.form.area.id,
        },
      });

      this.loadMeters();
    },
  },
});
