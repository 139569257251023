import { provideApolloClient } from '@vue/apollo-composable';
import { userStore } from '~/stores/user';
import localforage from 'localforage';
import { CLIENT_INSERT, CLIENTS_QUERY_LIST } from '~/graphql/client';
import type { ApiGetClient, Clients, ApiInsertClient, InsertClient } from '~/types/client';

interface State {
  clients: Clients;
}

export const clientStore = defineStore({
  id: 'clientStore',
  state: (): State => ({
    clients: [],
  }),
  getters: {
    getClients(): Clients {
      return this.clients;
    },
    getClient() {
      return (clientId: number) => {
        return this.clients.find((client) => client.id === clientId) ?? null;
      };
    },
  },
  actions: {
    async loadClients(): Promise<void> {
      const { setSelectedClient } = globalStore();
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const response = await apolloClient.query<ApiGetClient>({ query: CLIENTS_QUERY_LIST });
      this.clients = response.data.clients ?? [];

      const currentClientInForage = await localforage.getItem<number>(`nrjx-selected-client`);

      if (currentClientInForage) {
        const findedClient = this.clients.find((client) => client.id === currentClientInForage);
        if (findedClient) {
          await setSelectedClient(findedClient.id);
        } else if (this.clients[0]) {
          await setSelectedClient(this.clients[0].id);
        }
      } else if (this.clients[0]) {
        await setSelectedClient(this.clients[0].id);
      }
    },
    async createClient(client: InsertClient): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const user = userStore().getCurrentUser;
      if (user && user?.role === 'admin_app') {
        await apolloClient.mutate<ApiInsertClient>({
          mutation: CLIENT_INSERT,
          variables: {
            object: client,
          },
        });
        await this.loadClients();
      }
    },
  },
});
