import { gql } from '@apollo/client/core';

const actionFields = `
  id
  name
  site_id
  status
  month_impact_consumption
  month_impact_cost
  period_end
  period_start
  recurring
  reference
  time_slots
  value
  created_at
  week_impact_consumption
  week_impact_cost
  year_impact_consumption
  year_impact_cost
  priority
  potential_saving
  description
  area_id
  area {
    id
    name
    meters {
      id
      meter_type {
        energy_type
        id
        name
        step_seconds
      }
    }
  }
  incident {
    id
    cost
  }
  owner_id
  owner {
    id
    first_name
    last_name
  }
`;

export const ACTION_QUERY_LIST = gql`
  query ActionQuery($site_id: [Int!]!) {
    actions(where: { site_id: { _in: $site_id } }) {
      ${actionFields}
    }
  }
`;

export const ACTION_QUERY_ONE = gql`
  query ActionQuery($id: Int!) {
    actions_by_pk(id: $id) {
      ${actionFields}
    }
  }
`;

export const ACTION_MUTATION_INSERT = gql`
  mutation ActionMutationInsert($object: actions_insert_input!) {
    insert_actions_one(object: $object) {
      ${actionFields}
    }
  }
`;

export const ACTION_MUTATION_UPDATE = gql`
  mutation ActionMutationUpdate($id: Int!, $object: actions_set_input!) {
    update_actions_by_pk(pk_columns: { id: $id }, _set: $object) {
      ${actionFields}
    }
  }
`;

export const ACTION_MUTATION_DELETE = gql`
  mutation ActionMutationDelete($id: Int!) {
    delete_actions_by_pk(id: $id) {
      id
    }
  }
`;
