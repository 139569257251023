import type { RouteRecordRaw } from 'vue-router';

export const profileRoutes: RouteRecordRaw[] = [
  {
    name: 'profile-index',
    component: () => import('./../pages/index.vue'),
    path: '/profile',
    meta: {
      middleware: 'auth',
    },
  },
];
