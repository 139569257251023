import type { RouteRecordRaw } from 'vue-router';

export const incidentRoutes: RouteRecordRaw[] = [
  {
    name: 'incident-index',
    component: () => import('./../pages/index.vue'),
    path: '/incidents',
    meta: {
      middleware: 'auth',
    },
  },
  {
    name: 'incident-view',
    component: () => import('./../pages/view.vue'),
    path: '/incident/:id',
    meta: {
      middleware: 'auth',
    },
  },
];
