import { defineStore } from 'pinia';
import { provideApolloClient, useSubscription } from '@vue/apollo-composable';
import { userStore } from '~/stores/user';
import {
  NOTIFICATION_MUTATION_INSERT,
  NOTIFICATION_MUTATION_UPDATE,
  NOTIFICATION_QUERY_LIST,
  NOTIFICATION_SUBSCRIPTION,
} from '~/graphql/notification';
import { ACTION_QUERY_ONE } from '~/graphql/action';
import type { Incident } from '~/types/incident';
import type { Action } from '~/types/action';
import type { Notification } from '~/types/notification';
import { INCIDENTS_QUERY_ONE } from '~/graphql/incidents';

interface NotificationState {
  notifications: Notification[];
}

export const notificationStore = defineStore({
  id: 'notification',
  state: (): NotificationState => ({
    notifications: [],
  }),
  getters: {
    getNotifications(): Notification[] {
      return this.notifications;
    },
  },
  actions: {
    async initNotifications() {
      const { result } = useSubscription(NOTIFICATION_SUBSCRIPTION, {
        user_id: userStore().getCurrentUser.id,
      });
      watch(result, () => {
        this.loadNotifications();
      });
    },
    async loadNotifications(): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      const notifications: { data: { notifications: Notification[] } } = await apolloClient.query({
        query: NOTIFICATION_QUERY_LIST,
        variables: {
          user_id: userStore().getCurrentUser.id,
        },
      });

      let notificationWithData: Notification[] = [];

      const promises = notifications.data.notifications.map(async (notification) => {
        const incident_id = notification.data?.incident_id;
        const action_id = notification.data?.action_id;
        const incidentType = ['incident-create-by-nrjx', 'incident-create-by-user', 'incident-message', 'incident-resolve'];
        if (incidentType.includes(notification.type) && incident_id) {
          return new Promise((resolve) => {
            resolve(this.getIncident(incident_id));
          });
        } else if (notification.type === 'action-create' && action_id) {
          return new Promise((resolve) => {
            resolve(this.getAction(action_id));
          });
        }
      });

      await Promise.all(promises).then((responses: any) => {
        notificationWithData = notifications.data.notifications.map((notification, index) => {
          const dataObject = {
            ...notification,
            data: {
              incident: undefined,
              incident_id: notification.data?.incident_id ?? undefined,
              action: undefined,
              action_id: notification.data?.action_id ?? undefined,
            },
          };
          if (dataObject.data?.incident_id) dataObject.data.incident = responses[index];
          if (dataObject.data?.action_id) dataObject.data.action = responses[index];
          return dataObject;
        });
      });

      this.notifications = notificationWithData;
    },
    async getIncident(id: number): Promise<Incident | undefined> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      const incident: { data: { incidents_by_pk: Incident } } = await apolloClient.query({
        query: INCIDENTS_QUERY_ONE,
        variables: {
          id: id,
        },
      });
      return incident.data.incidents_by_pk;
    },
    async getAction(id: number): Promise<Action | undefined> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      const action: { data: { actions_by_pk: Action } } = await apolloClient.query({
        query: ACTION_QUERY_ONE,
        variables: {
          id: id,
        },
      });
      return action.data.actions_by_pk;
    },

    // Set notification as read
    async setNotificationAsRead(notificationId: number): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      await apolloClient.mutate({
        mutation: NOTIFICATION_MUTATION_UPDATE,
        variables: {
          id: notificationId,
          object: {
            read: true,
          },
        },
      });
    },

    // Notification creation
    async createNotification(notification: Partial<Notification>): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      await apolloClient.mutate({
        mutation: NOTIFICATION_MUTATION_INSERT,
        variables: {
          object: notification,
        },
      });
    },
  },
});
