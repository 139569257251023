import { gql } from '@apollo/client/core';

export const AREA_QUERY_UPDATE = gql`
  mutation UpdateArea($id: Int!, $object: areas_set_input!) {
    update_areas(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
    }
  }
`;

export const AREAS_QUERY_LIST = gql`
  query AreasQuery($site_id: [Int!]!) {
    areas(where: { site_id: { _in: $site_id } }) {
      id
      children {
        id
        name
      }
      name
      parent_id
      parent {
        id
        name
      }
      fabrication_orders {
        id
        start
        end
        quantity
      }
      production_unit_id
      unit {
        symbol
        name
        id
      }
      production_reporting_frequency
      site_id
      meters {
        id
        meter_type {
          energy_type
          step_seconds
        }
      }
      site {
        id
        name
        production_start_weekday
      }
    }
  }
`;

export const AREA_QUERY = gql`
  query AreaQuery($id: Int!) {
    areas(where: { id: { _eq: $id } }) {
      id
      children {
        id
        name
      }
      name
      parent_id
      parent {
        id
        name
      }
      production_reporting_frequency
      site_id
      site {
        id
        name
      }
      fabrication_orders {
        id
        start
        end
        quantity
      }
      production_unit_id
    }
  }
`;

export const AREA_INSERT = gql`
  mutation InsertArea($object: areas_insert_input!) {
    insert_areas_one(object: $object) {
      site_id
      name
      id
    }
  }
`;
