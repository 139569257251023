<script setup lang="ts">
import * as icons from 'lucide-vue-next';

export type IconName = keyof Omit<typeof icons, 'iconNode'>;

const props = withDefaults(
  defineProps<{
    name: IconName;
    size?: number;
    color?: string;
    strokeWidth?: number;
    defaultClass?: string;
  }>(),
  {
    strokeWidth: 2,
  },
);

const icon = computed(() => icons[props.name]);
</script>
<template>
  <component :is="icon" :size="size" :color="color" :stroke-width="strokeWidth" :default-class="defaultClass" />
</template>
