import type { RouteRecordRaw } from 'vue-router';

export const adminRouter: RouteRecordRaw[] = [
  {
    name: 'admin-index',
    path: '/admin',
    component: () => import('../pages/index.vue').then((r) => r.default || r),
    meta: {
      middleware: 'auth',
    },
  },
];
