import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

export const useFirebase = () => {
  const config = useRuntimeConfig().public;

  const fb = initializeApp({
    apiKey: config.firebase.apiKey,
    authDomain: config.firebase.authDomain,
    projectId: config.firebase.projectId,
    storageBucket: config.firebase.storageBucket,
    messagingSenderId: config.firebase.messagingSenderId,
    appId: config.firebase.appId,
  });

  const fbAuth = getAuth(fb);

  return { fbAuth };
};
