import { gql } from '@apollo/client/core';

export const METER_TYPES_QUERY_LIST = gql`
  query MeterTypesQuery {
    meter_types {
      energy_type
      id
      name
      step_seconds
    }
  }
`;
