import { defineStore } from 'pinia';
import { provideApolloClient } from '@vue/apollo-composable';
import { cloneDeep } from '@apollo/client/utilities';
import { AREA_QUERY_UPDATE, AREAS_QUERY_LIST } from '~/graphql/area';
import type { Area } from '~/types/area';

interface AreasState {
  areas: Area[];
}

export const areaStore = defineStore({
  id: 'areaStore',
  state: (): AreasState => ({
    areas: [],
  }),
  getters: {
    getAreas(): Area[] {
      return this.areas.filter((area) => {
        return area.meters.length > 0;
      });
    },
    getArea: (state) => {
      return (area_id: number) => state.areas.find((area) => area.id === area_id);
    },
    getRootAreas(): Area[] {
      return this.getAreas.filter((area) => area.parent_id === null);
    },
    getAreaByMeterId: (state) => {
      return (meterId: number) => state.areas.find((area) => area.meters.some((meter) => meter.id === meterId));
    },
  },
  actions: {
    async loadAreas(): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      const areas: { data: { areas: Area[] } } = await apolloClient.query({
        query: AREAS_QUERY_LIST,
        variables: {
          site_id: globalStore().getSelectedSite,
        },
      });
      this.areas = cloneDeep(areas?.data?.areas);
    },
    async updateArea(options: { areaId: number; form: Partial<Area> }) {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      await apolloClient.mutate({
        mutation: AREA_QUERY_UPDATE,
        variables: { id: options.areaId, object: options.form },
      });
      await this.loadAreas();
    },
  },
});
