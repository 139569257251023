import type { RouteRecordRaw } from 'vue-router';

export const dashboardRouter: RouteRecordRaw[] = [
  {
    name: 'dashboard-index',
    path: '/',
    component: () => import('../pages/index.vue').then((r) => r.default || r),
    meta: {
      middleware: 'auth',
    },
  },
];
