import { gql } from '@apollo/client/core';

export const ALERT_ANALYTIC_TYPE_QUERY_LIST = gql`
  query AlertAnalyticTypeQuery {
    alert_types {
      value
    }
  }
`;

export const ALERTS_QUERY_LIST = gql`
  query AlertsQuery($site_id: [Int!]!) {
    alerts(where: { meter: { area: { site_id: { _in: $site_id } } } }) {
      id
      params
      type
      meter_id
      name
      _created_at
      _updated_at
      _created_by
      _updated_by
      meter {
        id
        area {
          id
          name
        }
        meter_type {
          energy_type
        }
      }
      incidents {
        name
      }
      alert_users {
        id
        user_id
        user {
          first_name
          last_name
          id
          client_id
          picture_url
        }
      }
    }
  }
`;

export const CREATE_ALERT = gql`
  mutation CreateAlert($object: alerts_insert_input!) {
    insert_alerts_one(object: $object) {
      id
      params
      type
      meter_id
      name
      _created_at
      _updated_at
      _created_by
      _updated_by
      meter {
        area {
          id
          name
        }
        meter_type {
          energy_type
        }
      }
      incidents {
        id
        area_id
        name
        start
        end
      }
      alert_users {
        id
        user_id
        user {
          first_name
          last_name
          id
          client_id
          picture_url
        }
      }
    }
  }
`;

export const UPDATE_ALERT = gql`
  mutation UpdateAlert($id: Int!, $object: alerts_set_input!) {
    update_alerts(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
      returning {
        id
        params
        type
        name
        meter_id
        _created_at
        _updated_at
        _created_by
        _updated_by
        meter {
          area {
            id
            name
          }
          meter_type {
            energy_type
          }
        }
        incidents {
          id
          area_id
          name
          start
          end
        }
        alert_users {
          id
          user_id
          user {
            first_name
            last_name
            id
            client_id
            picture_url
          }
        }
      }
    }
  }
`;

export const CREATE_ALERT_USERS = gql`
  mutation CreateAlertUser($objects: [alert_user_insert_input!]!) {
    insert_alert_user(objects: $objects) {
      affected_rows
    }
  }
`;

export const UDPATE_ALERT_USERS = gql`
  mutation UpdateAlertUser($id: Int!, $objects: [alert_user_insert_input!]!) {
    delete_alert_user(where: { alert_id: { _eq: $id } }) {
      affected_rows
    }
    insert_alert_user(objects: $objects) {
      affected_rows
    }
  }
`;

export const DELETE_ALERT = gql`
  mutation DeleteAlert($id: Int!) {
    delete_alerts_by_pk(id: $id) {
      id
    }
  }
`;
