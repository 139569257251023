import localforage from 'localforage';

import { meterStore } from './meter';
import type { Client } from '~/types/client';
import type { Site } from '~/types/site';

interface State {
  loading: boolean;
  selectedClient: Client['id'] | null;
  selectedSite: number[];
}

export const globalStore = defineStore({
  id: 'globalStore',
  state: (): State => ({
    loading: true,
    selectedClient: null,
    selectedSite: [],
  }),
  getters: {
    getLoading(): boolean {
      return this.loading;
    },
    getSelectedClient(): Client['id'] | null {
      return this.selectedClient;
    },
    getSelectedSite(): number[] {
      return this.selectedSite;
    },
  },
  actions: {
    async initApp() {
      this.loading = true;
      const firebase = firebaseStore();
      const user = userStore();
      if (firebase.getFirebaseUser) {
        this.loading = true;
        await user.loadUsers();

        // Load clients and set one as default
        await clientStore().loadClients();
        await siteStore().loadSites();

        await Promise.all([
          meterStore().loadMeters(),
          areaStore().loadAreas(),
          consumptionObjectiveStore().loadConsumptionObjectives(),
          meterTypesStore().loadMeterTypes(),
          unitsStore().loadUnits(),
          alertStore().loadAlerts(),
          alertStore().loadAlertAnalyticType(),
          notificationStore().initNotifications(),
          contractStore().loadContracts(),
        ]);
      }
      this.setLoading(false);
    },
    async setSelectedClient(clientId: Client['id'] | null) {
      this.selectedClient = clientId;
      await localforage.setItem<number>(`nrjx-selected-client`, clientId);
    },
    async setSelectedSite(siteId: Site['id'] | string) {
      if (typeof siteId === 'string' && siteId === 'all') {
        this.selectedSite = siteStore().getSites.map((site) => site.id);
        await localforage.setItem<number>(`nrjx-selected-site`, null);
      } else if (typeof siteId === 'number') {
        this.selectedSite = [siteId];
        await localforage.setItem<number>(`nrjx-selected-site`, siteId);
      }
    },
    setLoading(loading: boolean) {
      this.loading = loading;
    },
  },
});
