import { gql } from '@apollo/client/core';

export const UNITS_QUERY_LIST = gql`
  query Unit {
    units {
      id
      name
      symbol
      type
    }
  }
`;
