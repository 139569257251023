import { provideApolloClient } from '@vue/apollo-composable';
import localforage from 'localforage';
import type { Dropdown } from '~/components/ui/Dropdown.vue';
import { SITE_UPDATE_PRODUCTION_HOURS } from '~/graphql/production_hours';
import { SITE_INSERT, SITE_UPDATE, SITES_QUERY_LIST } from '~/graphql/site';
import type { ApiGetSites, InsertSite, Sites } from '~/types/site';

interface State {
  sites: Sites;
  week: {
    [key: string]: number;
  };
}

export const siteStore = defineStore({
  id: 'siteStore',
  state: (): State => ({
    sites: [],
    week: {
      sunday: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
    },
  }),
  getters: {
    getSites(): Sites {
      return this.sites;
    },
    getSite() {
      return (siteId: number) => {
        return this.sites.find((site) => site.id === siteId);
      };
    },
    getSitesAsOptions(): Dropdown[] {
      const options = this.sites.map((item) => {
        return {
          label: item.name,
          key: item.id,
        };
      });
      return options;
    },
  },
  actions: {
    async loadSites(): Promise<any> {
      const { setSelectedSite } = globalStore();
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const currentClient = globalStore().getSelectedClient;
      if (!currentClient) return;

      const response = await apolloClient.query<ApiGetSites>({
        query: SITES_QUERY_LIST,
        variables: { client_id: currentClient },
      });

      this.sites = response.data.sites.map((item) => {
        return {
          ...item,
          configs: {
            production_times: item.production_hours.map((item) => {
              const WEEKDAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
              return {
                day: WEEKDAYS[item.day_id],
                start: {
                  hours: item.start.split(':')[0],
                  minutes: item.start.split(':')[1],
                },
                end: {
                  hours: item.end.split(':')[0],
                  minutes: item.end.split(':')[1],
                },
                id: item.id,
                created_at: item.created_at,
                updated_at: item.updated_at,
              };
            }),
          },
        };
      });

      const currentSiteInForage = await localforage.getItem<number>(`nrjx-selected-site`);

      if (currentSiteInForage) {
        const findedSite = this.sites.find((site) => site.id === currentSiteInForage);
        if (findedSite) {
          await setSelectedSite(findedSite.id);
        } else if (this.sites[0]) {
          await setSelectedSite(this.sites[0].id);
        }
      } else {
        await setSelectedSite('all');
      }
    },
    async createSite(site: InsertSite): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      await apolloClient.mutate({ mutation: SITE_INSERT, variables: { object: site } });
      await this.loadSites();
    },

    // Production Hours
    async updateContractPower(id: number, contractPower: number): Promise<any> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const updatedSite = await apolloClient.mutate({
        mutation: SITE_UPDATE,
        variables: { object: { enedis_contract_power: contractPower }, id },
      });

      const index = this.sites.findIndex((item) => item.id === id);
      this.sites[index] = updatedSite.data.update_sites_by_pk;
      return updatedSite;
    },
    async updateContractInformations(id: number, contract: any): Promise<any> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const updatedSite = await apolloClient.mutate({
        mutation: SITE_UPDATE,
        variables: { object: contract, id },
      });

      const index = this.sites.findIndex((item) => item.id === id);
      this.sites[index] = updatedSite.data.update_sites_by_pk;
      return updatedSite;
    },
    async updateProductionHours(site: any): Promise<any> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const formatTime = (hours: any, minutes: any) => {
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      };
      const formatDayId = (date: any) => {
        const WEEKDAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        return WEEKDAYS.indexOf(date);
      };

      function updateProductionHours(site_id: string, objects: any) {
        if (site_id === null) {
          return {
            day_id: formatDayId(objects.day),
            end: formatTime(objects.end.hours, objects.end.minutes),
            start: formatTime(objects.start.hours, objects.start.minutes),
            site_id: site.id,
            updated_at: new Date(),
          };
        } else {
          return {
            day_id: formatDayId(objects.day),
            end: formatTime(objects.end.hours, objects.end.minutes),
            start: formatTime(objects.start.hours, objects.start.minutes),
            site_id: site.id,
            id: objects.id,
            updated_at: new Date(),
          };
        }
      }

      await apolloClient.mutate({
        mutation: SITE_UPDATE_PRODUCTION_HOURS,
        variables: {
          siteId: site.id,
          objects: site.production_hours.production_times.map((item: any) => {
            return updateProductionHours(item.id, item);
          }),
        },
      });

      await this.loadSites();
    },
    getProductionStartWeekday(): number {
      const currentSite = globalStore().getSelectedSite;
      const currentSiteStartWeeday = this.getSite(currentSite[0]).production_start_weekday as keyof typeof this.week;
      return this.week[currentSiteStartWeeday];
    },
  },
});
