import { gql } from '@apollo/client/core';

const incidentsFields = `
      alert_id
      closing_reason
      closing_reason_other_text
      cost
      end
      id
      name
      owner_id
      source_id
      start
      status
      type
      area_id
      area {
        id
        name
      }
      owner_user {
        picture_url
        first_name
        last_name
        id
      }
      source_user {
        role
        picture_url
        last_name
        id
        first_name
        email
        client_id
      }
      tasks {
        type
        status
        name
        incident_id
        id
        description
        assignee_id
        deadline
        user {
          role
          last_name
          picture_url
          id
          first_name
          client_id
          email
        }
      }
      tasks_aggregate(where: { status: { _eq: done } }) {
        aggregate {
          count(columns: _created_at)
        }
      }
      comments {
        _created_at
        _created_by
        content
        id
        incident_id
      }`;

export const INCIDENTS_QUERY_LIST = gql`
  query IncidentsQuery($site_id: [Int!]!) {
    incidents(where: {area: {site_id: { _in: $site_id }}}) {
      ${incidentsFields}
    }
  }
`;

export const INCIDENTS_QUERY_ONE = gql`
  query IncidentQuery($id: Int!) {
    incidents_by_pk(id: $id) {
      ${incidentsFields}
      alert {
        name
      }
    }
  }
`;

export const INCIDENTS_QUERY_BY_ID = gql`
  query IncidentsQuery($id: Int!) {
    incidents_by_pk(id: $id) {
      id
      end
      cost
      closing_reason_other_text
      closing_reason
      area_id
      area {
        id
        name
      }
      alert_id
      _updated_by
      _updated_at
      _created_by
      _created_at
      type
      status
      start
      source_id
      priority
      owner_id
      name
    }
  }
`;

export const INSERT_INCIDENT = gql`
  mutation CreateIncident($object: incidents_insert_input!) {
    insert_incidents_one(object: $object) {
      ${incidentsFields}
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation CreateComment($object: comments_insert_input!) {
    insert_comments_one(object: $object) {
      _created_at
      _created_by
      content
      id
      incident_id
    }
  }
`;

export const QUERY_COMMENTS_BY_INCIDENT_ID = gql`
  query QueryCommentsByIncidentId($id: Int!) {
    comments(where: { incident_id: { _eq: $id } }) {
      _created_at
      _created_by
      content
      id
      incident_id
    }
  }
`;

export const UPDATE_INCIDENT = gql`
  mutation UpdateIncident($id: Int!, $object: incidents_set_input!) {
    update_incidents_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      end
      cost
      closing_reason_other_text
      closing_reason
      area_id
      alert_id
      type
      status
      start
      source_id
      owner_id
      name
    }
  }
`;

export const INCIDENT_TYPES_QUERY_LIST = gql`
  query IncidentTypesQuery {
    incident_types {
      value
      comment
    }
  }
`;

export const INCIDENT_STATUSES_QUERY_LIST = gql`
  query IncidentStatusesQuery {
    incident_statuses {
      value
      incidents {
        alert_id
        closing_reason
        closing_reason_other_text
        cost
        end
        id
        name
        owner_id
        source_id
        start
        status
        type
        area_id
        area {
          id
          name
        }
        owner_user {
          picture_url
          first_name
          last_name
          id
        }
        source_user {
          role
          picture_url
          last_name
          id
          first_name
          email
          client_id
        }
        tasks {
          type
          status
          name
          incident_id
          id
          description
          assignee_id
          deadline
          user {
            role
            last_name
            picture_url
            id
            first_name
            client_id
            email
          }
        }
        tasks_aggregate(where: { status: { _eq: done } }) {
          aggregate {
            count(columns: _created_at)
          }
        }
        comments {
          _created_at
          _created_by
          content
          id
          incident_id
        }
      }
    }
  }
`;

export const INCIDENT_CLOSING_REASONS_QUERY_LIST = gql`
  query IncidentClosingReasonsQuery {
    incident_closing_reasons {
      value
      comment
    }
  }
`;
