import type { RouteRecordRaw } from 'vue-router';

export const authRouter: RouteRecordRaw[] = [
  {
    name: 'auth-login',
    path: '/auth/login',
    component: () => import('../pages/login.vue').then((r) => r.default || r),
    meta: {
      layout: 'auth',
      middleware: 'auth',
    },
  },
  {
    name: 'auth-reset',
    path: '/auth/reset-password',
    component: () => import('../pages/reset.vue').then((r) => r.default || r),
    meta: {
      layout: 'auth',
      middleware: 'auth',
    },
  },
];
