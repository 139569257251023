import { gql } from '@apollo/client/core';

export const OBJECTIVE_INSERT = gql`
  mutation InsertObjective($object: objectives_insert_input!) {
    insert_objectives_one(object: $object) {
      id
    }
  }
`;

export const OBJECTIVE_MILESTONE_INSERT_MANY = gql`
  mutation InsertObjectiveMilestone($objects: [objective_milestones_insert_input!]!) {
    insert_objective_milestones(objects: $objects) {
      affected_rows
    }
  }
`;

export const OBJECTIVES_QUERY_LIST = gql`
  query QueryObjectives {
    objectives(where: { is_archived: { _eq: false } }) {
      id
      name
      start
      end
      target
      type
      objective_milestones {
        target
        timestamp
      }
      meter {
        id
        data_source_id
        area {
          name
          site {
            id
            client_id
          }
        }
        meter_type {
          energy_type
          name
        }
      }
    }
  }
`;
