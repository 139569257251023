import type { RouteRecordRaw } from 'vue-router';

export const componentLibraryRoutes: RouteRecordRaw[] = [
  {
    name: 'component-library-index',
    component: () => import('./../pages/index.vue'),
    path: '/component-library/',
    children: [
      {
        name: 'component-library-button',
        component: () => import('./../pages/button/index.vue'),
        path: 'button',
      },
    ],
  },
];
