<script lang="ts" setup>
// Stores
const global = globalStore();
const firebase = firebaseStore();

// Init App Function
const init = async () => {
  await firebase.initAuth();
  global.initApp();
};
init();
</script>
<template>
  <nuxt-layout v-if="!global.loading">
    <nuxt-page />
  </nuxt-layout>
  <div v-else class="flex items-center justify-center flex-col h-screen w-screen">
    <img src="./assets/full-logo.png" class="h-24 mb-4" />
    <ui-loader />
  </div>

  <!-- Modal and toast -->
  <ui-toast />
  <ui-alert />
</template>
