import { gql } from '@apollo/client/core';

export const CLIENTS_QUERY_LIST = gql`
  query ClientQueryList {
    clients {
      id
      name
      slug
      firebase_id
    }
  }
`;

export const CLIENT_QUERY = gql`
  query ClientQuery($id: Int!) {
    clients(where: { id: { _eq: $id } }) {
      id
      name
      slug
      firebase_id
    }
  }
`;

export const CLIENT_UPDATE = gql`
  mutation UpdateClient($object: clients_set_input!, $id: Int!) {
    update_clients_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      name
      slug
      firebase_id
    }
  }
`;

export const CLIENT_INSERT = gql`
  mutation InsertClient($object: clients_insert_input!) {
    insert_clients_one(object: $object) {
      id
      name
      slug
      firebase_id
      sites {
        id
        areas {
          id
          meters {
            id
          }
        }
      }
    }
  }
`;
export const CREATE_OBJECTIVE_WITH_MILESTONES = gql`
  mutation CreateObjectiveWithMilestones($object: objectives_insert_input!) {
    insert_objectives_one(object: $object) {
      id
      name
      start
      end
      target
      type
      meter_id
      objective_milestones {
        target
        timestamp
      }
    }
  }
`;
