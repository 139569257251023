import { gql } from '@apollo/client/core';

export const CONTRACT_QUERY_LIST = gql`
  query ContractQueryList($site_id: [Int!]!) {
    contracts(where: { site_id: { _in: $site_id } }) {
      start
      end
      energy_type
      emission_factor
      created_at
      contract_power
      id
      site_id
      updated_at
      tariffs {
        value
        name
        months
        contract_id
        tariff_schedules {
          id
          days_of_week
          time_range
        }
        id
      }
    }
  }
`;

export const CONTRACT_MUTATION_INSERT = gql`
  mutation ContractMutationInsert($object: contracts_insert_input!) {
    insert_contracts_one(object: $object) {
      start
      end
      energy_type
      emission_factor
      created_at
      contract_power
      updated_at
      tariffs {
        value
        name
        months
        tariff_schedules {
          days_of_week
          time_range
        }
      }
    }
  }
`;

export const CONTRACT_MUTATION_UPDATE = gql`
  mutation ContractMutationUpdate($object: contracts_set_input!, $id: Int!) {
    delete_tariffs(where: { contract_id: { _eq: $id } }) {
      returning {
        id
      }
    }
    update_contracts_by_pk(pk_columns: { id: $id }, _set: $object) {
      start
      end
      energy_type
      emission_factor
      created_at
      contract_power
      updated_at
    }
  }
`;

export const TARIFF_MUTATION_INSERT = gql`
  mutation TariffMutationInsert($objects: [tariffs_insert_input!]!) {
    insert_tariffs(objects: $objects) {
      returning {
        contract_id
        value
        name
        months
        tariff_schedules {
          days_of_week
          time_range
        }
      }
    }
  }
`;

export const CONTRACT_QUERY_BY_ID = gql`
  query ContractQueryById($id: Int!) {
    contracts_by_pk(id: $id) {
      start
      end
      energy_type
      emission_factor
      created_at
      contract_power
      id
      site_id
      updated_at
      tariffs {
        value
        name
        months
        contract_id
        tariff_schedules {
          id
          days_of_week
          time_range
        }
        id
      }
    }
  }
`;
