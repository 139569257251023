import { onAuthStateChanged, signInWithEmailAndPassword, SAMLAuthProvider, signInWithPopup, type UserCredential } from 'firebase/auth';
import { useI18n } from 'vue-i18n';

interface State {
  firebaseUser: null | UserCredential['user'];
  unsubscribe: null | (() => void);
  i18n: ReturnType<typeof useI18n>;
}

export const firebaseStore = defineStore({
  id: 'firebaseStore',
  state: (): State => ({
    firebaseUser: null,
    unsubscribe: null,
    i18n: useI18n(),
  }),
  getters: {
    getFirebaseUser(): UserCredential['user'] | null {
      return this.firebaseUser;
    },
  },
  actions: {
    async initAuth() {
      const { fbAuth } = useFirebase();
      await new Promise((resolve) =>
        onAuthStateChanged(fbAuth, async (user: any) => {
          if (user) {
            const userId: string = user?.uid ? user?.uid : '';
            this.firebaseUser = user;
            resolve(userId);
          } else {
            this.firebaseUser = null;
            resolve(null);
          }
        }),
      );
    },
    async signIn(form: { email: string; password: string }) {
      const { fbAuth } = useFirebase();
      const { initApp } = globalStore();
      const { proxy } = useScriptSegment();
      const router = useRouter();

      try {
        const data = await signInWithEmailAndPassword(fbAuth, form.email, form.password);
        this.firebaseUser = data.user;

        await initApp();

        const user = userStore().getCurrentUser;
        proxy.track('login', {
          id: user.id,
          client_name: user.client.name,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
        });

        proxy.identify(user.id, {
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
          clientName: user.client.name,
          client_id: user.client_id,
          role: user.role,
        });
        proxy.group(user.client_id.toString(), { name: user.client.name });

        router.push('/');
      } catch (error) {
        if (error.code === 'auth/user-not-found') {
          toastStore().showToast({
            type: 'error',
            title: this.i18n.t('global.error'),
            message: this.i18n.t('global.error_login_invalid_user'),
          });
        } else if (error.code === 'auth/invalid-credential') {
          toastStore().showToast({
            type: 'error',
            title: this.i18n.t('global.error'),
            message: this.i18n.t('global.error_login_invalid_password'),
          });
        } else {
          toastStore().showToast({
            type: 'error',
            title: this.i18n.t('global.error'),
            message: this.i18n.t('global.error_login_unknown'),
          });
        }
      }
    },
    async signInWithSSO(saml: string) {
      const { fbAuth } = useFirebase();
      const { initApp } = globalStore();
      const { proxy } = useScriptSegment();
      const router = useRouter();

      try {
        const provider = new SAMLAuthProvider(saml);
        const data = await signInWithPopup(fbAuth, provider);
        this.firebaseUser = data.user;

        await initApp();

        const user = userStore().getCurrentUser;
        proxy.track('login', {
          id: user.id,
          client_name: user.client.name,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
        });

        proxy.identify(user.id, {
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
          clientName: user.client.name,
          client_id: user.client_id,
          role: user.role,
        });
        proxy.group(user.client_id.toString(), { name: user.client.name });
        router.push('/');
      } catch (error) {
        toastStore().showToast({
          type: 'error',
          title: this.i18n.t('global.error'),
          message: this.i18n.t('global.error_login_unknown'),
        });
        throw error;
      }
    },
    async logout() {
      const { fbAuth } = useFirebase();
      await fbAuth.signOut();
    },
  },
});
